/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement("section", null, React.createElement("div", null, React.createElement(_components.h2, null, "Les meilleurs quartiers pour se loger à Prague"), React.createElement("p", null, "Pour se loger, Prague s’articule en trois quartiers principaux :"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/cz/prague/staremesto.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Staré Město"), ", la vieille ville, représente le centre historique de la capitale."), "\n", React.createElement(_components.li, null, "Autour, ", React.createElement("a", {
    href: "https://www.booking.com/district/cz/prague/prague-centre.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Nové Město"), ", la nouvelle ville, est un immense et élégant quartier, de la place Charles à la ", React.createElement("a", {
    href: "https://www.booking.com/district/cz/prague/wenceslas-square.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "place Venceslas"), ", non loin de la ", React.createElement("a", {
    href: "https://www.booking.com/landmark/cz/prague-main-railway-station.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "gare centrale de Prague"), "."), "\n", React.createElement(_components.li, null, "De l’autre côté de la Vltava, relié à la vieille ville par le ", React.createElement("a", {
    href: "https://www.booking.com/landmark/cz/charles-bridge.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "pont Charles"), ", on trouve ", React.createElement("a", {
    href: "https://www.booking.com/district/cz/prague/malastrana.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Malá Straná"), ", \"le petit côté\". Les ruelles romantiques de ce quartier sont dominées par ", React.createElement("a", {
    href: "https://www.booking.com/district/cz/prague/hradcany.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Hradčany"), ", le château de Prague."), "\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
